
import styled from 'styled-components'

const Styling = styled.div`



    .intro{
    background:var(--white);
    padding:10rem 2rem 6rem 2rem;

        @media only screen and (min-width: 350px) {
        padding:10rem 4rem 6rem 4rem;
        }
 
        @media only screen and (min-width: 1024px) {
        padding:16rem 8rem;
        }

        .quick-nav{
        display: none;

        @media only screen and (min-width: 768px) {
            display: inline-flex;
        }

            li{
            margin-right:4rem;
            position: relative;
            cursor: pointer;

                button{
                border:0;
                padding:0;
                }
            
                &:after{
                content:"";
                width:0.7rem;
                height:0.7rem;
                background:var(--black);
                position: absolute;
                border-radius: 100%;
                ${({theme}) => theme.easing('0.25')}  
                left:-1.5rem;
                top:calc(50% - 0.35rem);
                }

                &:hover:after{
                    transform:scale(2);
                }
            }

        }

    }

    h2{
    ${({theme}) => theme.tomato('regular')}  
    text-transform:uppercase;
    letter-spacing: -0.15rem;
    position: relative;
    width: auto;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    font-size:3.5rem;
    line-height:2rem;

        
        @media only screen and (min-width: 350px) {
        font-size:3rem;
        line-height:2.6rem;
        letter-spacing: -0.2rem;        
        }

        @media only screen and (min-width: 420px) {
        font-size:4rem;
        line-height:3rem;
        letter-spacing: -0.3rem;
        }
        
        @media only screen and (min-width: 610px) {
        font-size:5rem;
        line-height:4rem;
        letter-spacing: -0.4rem;
        }

        @media only screen and (min-width: 768px) {
        margin-bottom: 4rem;
        }
        
        @media only screen and (min-width: 800px) {
        font-size:6rem;
        line-height:5rem;
        }
        @media only screen and (min-width: 1024px) {
        font-size:clamp(6rem, 8vw, 12rem);
        line-height: clamp(6rem/1.15, 8vw/1.15, 12rem/1.15);
        margin-bottom: clamp(6rem/2, 6vw/2, 20rem/2);
        }
    
        span{
        width: 100%;
        align-items: center;
        display: flex;
        }

        .arrow{
        width: 1em;
        height:1em;
        transform: scale(0.6) translateX(-50%) translateY(20%);
        margin-left:1rem;

            @media only screen and (min-width: 768px) {
            margin-left:2rem;
            }

            path{
            fill:var(--black);
            }

        }
    }

`

export { Styling }