import React  from 'react'
import { graphql } from 'gatsby'    
import ImageText from '../../components/reusable/ImageText'       
import ArrowDown from '../../images/arrow_down.inline.svg'
import {Styling} from '../../styles/pages/programmes.styled'
import Seo from '../../components/global/seo'
import gsap from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default function Programmes({data}) {
    const programmes = data.wpPage.our_programmes.programmes

    const handleClick = (e) => {
        const target = e.target.closest('li').getAttribute('data-target');
        if(typeof window !== 'undefined'){
          gsap.to(window, {scrollTo:(`.${target}`)});
        }
    }

    return (
        <Styling>
          <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} />
          <div className="intro">
              <h2 className="title">Our <br />Programmes<ArrowDown className="arrow title-arrow"/></h2>
              <ul className="quick-nav">
              {
                  programmes.map((program, index) => {
                      return   (
                          <li key={`program_${index+1}`}  data-target={`program_${index+1}`}>
                            <button onClick={(e) => handleClick(e)} >{program.title}</button>
                          </li>
                      )
                  })
              }
              </ul>
          </div>
          {
              programmes.map((program, index) => {
                  return   (
                      <ImageText  
                      className={`program_${index+1}`}
                      image={program.image}
                      text={program.text}
                      title={program.title}
                      button={program.button} />
                  )
              })
              
          }
        </Styling>
    )
}

export const query = graphql`
  {
    wpPage(title: {eq: "Our Programmes"}) {
      title
      seo{
        metaDesc
        title
      }
      our_programmes {
        programmes {
          title
          text
          button {
            text
            link
          }
          image {
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
            }
          }
        }
      }
    }
  }
`